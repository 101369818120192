var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    { staticClass: "index-page" },
    [
      _c("page-title"),
      _c("div", { staticClass: "welcome-inner" }, [
        _c("p", { staticClass: "welcome-message" }, [
          _vm._v(" 欢迎使用" + _vm._s(_vm.projectTitle) + " ")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }