<!-- index -->
<template>
  <el-main class="index-page">
    <page-title />
    <div class="welcome-inner">
      <p class="welcome-message">
        欢迎使用{{ projectTitle }}
      </p>
    </div>
  </el-main>
</template>
<script>

import {cookie} from "../assets/js/config";

export default {
  components: {},
  data () {
    return {
      projectTitle: '',
    }
  },
  watch: {
  },
  created () {
    this.projectTitle = cookie.globalWangName()
  },
  methods: {
  }
}
</script>
<style>
.welcome-inner {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -18px;
}
.welcome-message {
  font-size: 36px;
  line-height: 1;
  text-align: center;
  font-weight: 200;
}
</style>
